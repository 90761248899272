<template>
    <el-tabs type="border-card" v-model="activeRoundTab" ref="rounds">
        <el-tab-pane v-for="round in activeRounds" :key="round.id">
                <span slot="label">
                    {{ round.name }}
                </span>

            <div>
                <div class="row">
                    <div class="col">
                        <table class="results table">
                            <thead>
                            <tr>
                              <th colspan="9">{{ roster.name }} Roster</th>
                            </tr>
                            </thead>

                            <bid-roster v-for="team in roster.teams"
                                        :bidders="bidders[team.id]"
                                        v-bind="{ team, round }"
                                        :key="team.id" />
                        </table>
                    </div>
                </div>
            </div>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import { Tabs, TabPane } from 'element-ui'
import { mapGetters } from 'vuex'
import BidRoster from '@/views/area/BidRoster'

import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const RosterRepository = RepositoryFactory.get('roster')

export default {
  name: 'round-tabs',
  components: {
    BidRoster,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane
  },
  props: {},
  data () {
    return {
      roster: [],
      activeRoundTab: 0
    }
  },
  methods: {
    fetchRoster () {
      return RosterRepository.get(this.selectedRound.roster_id)
        .then(r => {
          this.roster = r.data
        })
    }
  },
  computed: {
    ...mapGetters({
      activeRounds: 'round/activeRounds'
    }),
    bidders () {
      return this.$store.getters['bidder/roundBidders'](this.selectedRound.id)
    },
    selectedRound () {
      return this.activeRounds[this.activeRoundTab]
    }
  },
  created () {
    if (this.selectedRound) { this.fetchRoster() }
  },
  watch: {
    activeRoundTab (n, o) {
      if (n !== o) { this.fetchRoster() }
    }
  }
}
</script>

<style scoped>

</style>
