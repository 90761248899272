<template>
    <table class="results table">
        <tr class="subhead-light d-flex">
            <td class="col-5 col-lg-4">Name</td>
            <td class="col-2">Round</td>
            <td class="d-none d-md-table-cell col-md-1 text-center">Init</td>
            <td class="col-5 col-md-4 col-lg-3">Status</td>
            <td class="d-none d-lg-table-cell col-lg-2 text-right">Accrued/Accum.</td>
        </tr>

        <tr class="d-flex" v-for="bidder in bidders" :key="bidder.id">
            <td class="col-5 col-lg-4">
                <el-tooltip class="item" v-if="is('super')">
                    <p slot="content" class="m-0"><b>Bidder ID:</b> {{  bidder.id }}<br><b>Member #: </b>{{ bidder.member_id }}</p>
                    <i class="fas fa-user-astronaut cursor-pointer" />
                </el-tooltip>

                <span>
                    {{ loggedIn ? bidder.lname + ', ' + bidder.fname : '********, ********' }}
                </span>
                <i class="d-md-none" v-if="loggedIn">({{ bidder.init | uppercase }})</i>
            </td>
            <td class="col-2">
                {{ rounds[bidder.round_id] ? rounds[bidder.round_id].name : '' }}
            </td>
            <td class="d-none d-md-table-cell col-md-1 text-center text-uppercase">
                <b>{{ bidder.init }}</b>
            </td>
            <td class="col-5 col-md-4 col-lg-3 text-center">
                <bid-button v-bind="{
                                round: rounds[bidder.round_id],
                                bidder
                            }" small />

                <member-bid-status v-bind="{
                            round: rounds[bidder.round_id],
                            bidder,
                        }" class="ml-1"/>
            </td>
            <td class="d-none d-lg-table-cell col-lg-2 text-right">
                {{ bidder.balance_accrued || 0 }} / {{ bidder.balance_accumulated || 0 }}

                <member-leave class="ml-2"
                              :name="bidder.full_name"
                              :member_id="bidder.member_id" />
            </td>
        </tr>
    </table>
</template>

<script>
    import { mapGetters } from "vuex";
    import { BidButton, MemberBidStatus, MemberLeave } from "../../components";

    export default {
        name: "up-to-bid-table",
        components: {
            BidButton,
            MemberBidStatus,
            MemberLeave,
        },
        props: {
            bidders: Array,
        },
        data() {
            return {
                showMemberLeave: {},
            }
        },
        methods: {
        },
        computed: {
            ...mapGetters({
                is: 'auth/is',
                loggedIn: 'auth/loggedIn',
                facility: 'facility/facility',
                area: 'area/area',
                rounds: 'round/rounds',
            }),
        },
        watch: {}
    }
</script>

<style scoped>

</style>
