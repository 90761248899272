<template>
    <div>
        <div class="card card-stats card-raised">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="statistics">
                            <div class="info">
                                <div class="icon icon-info">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                <h3 class="info-title">
                                    <animated-number :value="stats.slots_required"></animated-number>
                                </h3>
                                <h6 class="stats-title">Pre-Approved Slots Required</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="statistics">
                            <div class="info">
                                <div class="icon icon-success">
                                    <i class="fas fa-calendar-check"></i>
                                </div>
                                <h3 class="info-title">
                                    <animated-number :value="stats.preapproved_slots_bid"></animated-number>
                                </h3>
                                <h6 class="stats-title">Pre-Approved Slots Taken</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="statistics">
                            <div class="info">
                                <div class="icon icon-warning">
                                    <i class="fas fa-calendar"></i>
                                </div>
                                <h3 class="info-title">
                                    <animated-number :value="stats.preapproved_slots_remaining"></animated-number>
                                </h3>
                                <h6 class="stats-title">Pre-Approved Slots Remaining</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col d-none d-md-block">
                        <div class="statistics">
                            <div class="info">
                                <div class="icon icon-primary">
                                    <i class="fas fa-calendar-check"></i>
                                </div>
                                <h3 class="info-title">
                                    <animated-number :value="stats.preapproved_slots_available"></animated-number>
                                </h3>
                            </div>
                            <h6 class="stats-title">Preapproved Slots on Calendar</h6>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-12 col-lg-6">
                <card class="card-chart" no-footer-line>
                    <div slot="header">
                        <h3 class="card-title">
                            Leave Summary
                        </h3>
                    </div>
                    <div>
                        <n-progress label="Pre-Approved Slots Remaining"
                                    :type="progressPercent <= 25 ? 'warning' : 'success'"
                                    :value="progressPercent"
                                    show-value></n-progress>

                        <div class="row mt-3 mb-1">
                            <div class="col">
                                <h5 class="m-0">Pre-Approved Leave Slots</h5>
                                <small class="d-block mb-2">Slots/hours required by the CBA for this area.</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <h6 class="leave-desc d-inline-block m-0 mr-2">Required</h6>
                                    <area-members v-if="is('arearep', area.id)"
                                                  @refresh="$store.dispatch('area/fetchStats')" />
                                </div>

                                {{ stats.slots_required }}
                                <small>({{ stats.hours_required }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Taken</h6>
                                {{ stats.preapproved_slots_bid }}
                                <small>({{ stats.preapproved_hours_bid }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Remaining</h6>
                                {{ stats.preapproved_slots_remaining }}
                                <small>({{ stats.preapproved_hours_remaining }} hours)</small>
                            </div>
                        </div>
                        <hr>
                        <div class="row mt-3 mb-1">
                            <div class="col">
                                <h5 class="m-0">Leave Slot Summary</h5>
                                <small class="d-block mb-2">All slots (Includes non-preapproved) available for bidding.</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h6 class="leave-desc">Slots Available</h6>
                                {{ stats.slots_available }}
                                <small>({{ stats.hours_available }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Taken</h6>
                                {{ stats.bid_slots }}
                                <small>({{ stats.bid_hours }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Holidays</h6>
                                {{ stats.bid_holiday_slots }}
                                <small>({{ stats.bid_holiday_hours }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Remaining</h6>
                                {{ stats.slots_remaining }}
                                <small>({{ stats.hours_remaining }} hours)</small>
                            </div>
                        </div>
                        <hr>
                        <div class="row mt-3 mb-1">
                            <div class="col">
                                <h5 class="m-0">{{ facility.bid_year }} Member Hours</h5>
                                <small class="d-block mb-2">Sum of hours accrued and hours bid for all controllers in this area.</small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h6 class="leave-desc">Starting</h6>
                                <i>Accrued:</i> {{ stats.member_accrual_beginning }}<br>
                                <i>Accumulated:</i> {{ stats.member_accumulated_beginning }}
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Remaining</h6>
                                <i>Accrued:</i> {{ stats.member_accrued_remaining }}<br>
                                <i>Accumulated:</i> {{ stats.member_accumulated_remaining }}
                            </div>
                        </div>
                    </div>
                </card>
                <card class="card-chart" no-footer-line>
                    <div slot="header">
                        <h3 class="card-title">
                            Leave Type Summary
                        </h3>
                    </div>
                    <div v-for="(type, type_id) in stats.summary_by_type" :key="type_id">
                        <div class="mb-3" v-if="getType(type_id)">
                            <h4 class="m-0 d-inline-block align-middle">{{ getType(type_id).name }}</h4>
                            <n-button :type="type.is_preapproved ? 'success' : 'warning'"
                                      round
                                      class="d-inline-block bid-button small m-0 ml-2">
                                {{ type.is_preapproved ? 'Pre-Approved' : 'Non Prime Time Leave' }}
                            </n-button>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h6 class="leave-desc">Slots Available</h6>
                                {{ type.total_slots }}
                                <small>({{ type.total_hours }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Taken</h6>
                                {{ type.bid_slots}}
                                <small>({{ type.bid_hours }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Holidays</h6>
                                {{ type.bid_holiday_slots }}
                                <small>({{ type.bid_holiday_hours }} hours)</small>
                            </div>
                            <div class="col">
                                <h6 class="leave-desc">Remaining</h6>
                                {{ type.slots_remaining }}
                                <small>({{ type.hours_remaining }} hours)</small>
                            </div>
                        </div>
                        <hr>
                    </div>
                </card>
            </div>
            <div class="col-md-12 col-lg-6">
                <card class="card-chart" no-footer-line>
                    <div slot="header">
                        <h3 class="card-title">
                            Up To Bid
                        </h3>
                    </div>

                    <up-to-bid-table v-if="bidders.length && checkPrivacy" :bidders="filteredBidders"></up-to-bid-table>
                    <h6 class="text-gray" v-else-if="!checkPrivacy">Must be logged in to view</h6>
                    <h6 class="text-gray" v-else>No bidders available</h6>
                </card>

                <card class="card-chart" no-footer-line>
                    <div slot="header">
                        <h3 class="card-title">
                            Active Rounds
                        </h3>
                    </div>

                    <round-tabs v-if="activeRounds.length > 0 && checkPrivacy"></round-tabs>
                    <h6 class="text-gray" v-else-if="!checkPrivacy">Must be logged in to view</h6>
                    <h6 class="text-gray" v-else>No active rounds</h6>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import RoundTabs from '@/views/area/RoundTabs'
import { AreaMembers } from '@/components'
import { AnimatedNumber, Progress as NProgress } from '@/components/stateless'
import UpToBidTable from './UpToBidTable'

export default {
  name: 'area-home',
  components: {
    RoundTabs,
    AreaMembers,
    AnimatedNumber,
    NProgress,
    UpToBidTable
  },
  data () {
    return {}
  },
  methods: {
    getType (type_id) {
      return _.find(this.types, { id: parseInt(type_id) })
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      facility: 'facility/facility',
      checkPrivacy: 'facility/checkPrivacy',
      area: 'area/area',
      areaSet: 'area/isSet',
      stats: 'area/stats',
      types: 'leave/types',
      bidders: 'bidder/bidders',
      activeRounds: 'round/activeRounds'
    }),
    progressPercent () {
      // Make sure slots established
      if (!this.stats.slots_required) return 0

      const remain = this.stats.slots_required - this.stats.preapproved_slots_bid
      return this.stats ? Math.ceil(remain / this.stats.slots_required * 100) : 0
    },
    preapprovedRemaining () {
      // return this.
    },
    filteredBidders () {
      return _.filter(this.bidders, b => _.find(this.activeRounds, { id: b.round_id }) && b.up_to_bid)
    }
  },
  created () {
    // Update stats
    if (!_.isEmpty(this.area)) { this.$store.dispatch('area/fetchStats') }
  },
  watch: {}
}
</script>
<style>

</style>
